<template>
  <div>
    <button
      @click="goToCorrespondingPage"
      style="border-radius: none"
      class="fancy-btn"
    >
      <slot></slot>
    </button>
  </div>
</template>

<script>
//import { EventBus } from "../../main.js";
export default {
  props: {
    entity: Object,
    type: String,
  },
  data() {
    return {};
  },
  methods: {
    goToCorrespondingPage() {
      var route = "";
      route = this.type;
      this.$store.state.entity=this.entity;
      this.$router.push("/" + route);
    },
  },
};
</script>

<style scoped>
.fancy-btn {
  margin: 2rem auto;
  width: 30rem;
  border-radius: 12px;
  box-shadow: 02px 8px rgb(60, 60, 64);
  padding: 1rem;
}
</style>
