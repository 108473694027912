const unittypes = [
  "ONE_BHK",
  "TWO_BHK",
  "THREE_BHK",
  "FOUR_BHK",
  "FIVE_BHK",
  "OTHER",
];
const propertytypes = [
  "RESIDENTIAL",
  "APARTMENT",
  "PAYING_GUEST",
  "COMMERCIAL",
];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// ["Add Tenant", "Property", "Unit", "Tenants", "Reports"];
const actions = {
  "Add Tenant": "addTenant",
  Property: "props",
  Unit: "units",
  Tenants: "tenants",
  Reports: "reports",
};

export default {
  unittypes,
  propertytypes,
  actions,
  months,
};
