<template>
  <div class="signup">
    <div class="header">
      <h1>RENTO</h1>
      <div class="home_button">
        <button @click="gotoDash">DashBoard</button>
      </div>
    </div>
    <p>Coming Soon</p>
    <!-- <ul>
      <BasicCard v-for="(num, index) in nums" :key="index">{{
        num.unit_name
      }}</BasicCard>
      <br />
    </ul> -->

    <footer>
      <p><strong>About us</strong></p>
      <p>
        Rento is a completly free rental property management application
        designed for small to mid size.
      </p>
      <p>
        we are comitted to roll out new features based on customer experience.
      </p>
      <div class="address">
        <p><strong>Rento</strong></p>
        <p>192, Gandhi road, Tirupati, Andhra pradesh, India</p>
      </div>
    </footer>
  </div>
</template>

<script>
//import { APISettings } from '../config.js';
//import { ref } from 'vue';
//`${num.unit_name} ${num.unit_type}`
// import BasicCard from "./cards/BasicCard.vue";
import OwnerMethods from "../api/resources/OwnerMethods.js";
const params = new URLSearchParams({
  entity: "unit",
});

export default {
  // components: { BasicCard },
  data() {
    return { nums: [] };
  },
  methods: {
    gotoDash() {
      this.$router.push("/dash");
    },
    async getUnits() {
      const fe = await OwnerMethods.ownerFetch(params);
      console.log(fe);
      this.nums = fe;
      //return fe;
    },
  },
  mounted() {
    this.getUnits();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.auth {
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  position: absolute;
  right: 0px;
  top: 50px;
}
.home_button {
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  position: absolute;
  left: 0px;
  top: 50px;
}
.header {
  padding: 15px;
  text-align: center;
  background: #675fcf;
  color: rgb(255, 255, 255);
  font-size: 20px;
}
.date_picker {
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
footer {
  padding: 1px;
  text-align: center;
  background: #675fcf;
  color: rgb(255, 255, 255);
  font-size: 15px;
  bottom: 0;
  position: relative;
  height: 180px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
}
</style>
