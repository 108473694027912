<template>
  <div class="signup">
    <div class="header">
      <h1>RENTO</h1>
      <div class="auth">
        <button @click="logout">Logout</button>
      </div>
    </div>
    <h3 style="color: #675fcf">
      {{ months[date.getMonth()] }} {{ date.getFullYear() }}
    </h3>
    <div class="dash">
      <div class="float-child">
        <h4>Received Amount</h4>
        <p>{{ received_amount }}</p>
      </div>
      <div class="float-child">
        <h4>Due Amount</h4>
        <p>{{ due_amount }}</p>
      </div>
    </div>

    <div class="due"><h3 style="text-align: left">Payment Due Details</h3></div>
    <h3 style="text-align: left">Actions</h3>
    <div class="actions">
      <ActionCard v-for="action in actions" :key="action" :action="action"
        ><img
          :src="getImgUrl(action)"
          alt="digital property management"
          width="50"
          height="30"
        />
        <p>{{ action }}</p></ActionCard
      >
    </div>
    <h3 style="text-align: left">Vancant Unit Details</h3>
    <base-spinner v-if="isLoading"></base-spinner>
    <div v-if="!isLoading && this.units.length == 0">
      No Vacant Units are available!
    </div>
    <div class="vacancy">
      <ul>
        <BasicCard
          v-for="(unit, index) in units"
          :key="index"
          :entity="unit"
          :type="'unit'"
          >{{ unit.unit_name }}</BasicCard
        >
        <br />
      </ul>
    </div>
    <footer>
      <p><strong>About us</strong></p>
      <p>
        Rento is a completly free rental property management application
        designed for small to mid size.
      </p>
      <p>
        we are comitted to roll out new features based on customer experience.
      </p>
      <div class="address">
        <p><strong>Rento</strong></p>
        <p>192, Gandhi road, Tirupati, Andhra pradesh, India</p>
      </div>
    </footer>
  </div>
</template>

<script>
import BasicCard from "./cards/BasicCard.vue";
import Constants from "../constants/Constants.js";
import ActionCard from "./cards/ActionCard.vue";
import OwnerMethods from "../api/resources/OwnerMethods.js";
const params = new URLSearchParams({
  entity: "unit",
});

export default {
  components: { ActionCard, BasicCard },
  data() {
    return {
      isLoading: false,
      received_amount: 0,
      due_amount: 0,
      months: Constants.months,
      date: new Date(),
      actions: [],
      units: [],
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace("/");
    },
    getImgUrl(pic) {
      //var images = require.context("../assets/", false, /\.png$/);
      return require("../assets/" + pic + ".png");
    },
    async getVacantUnits() {
      this.isLoading = true;
      const resp = await OwnerMethods.ownerFetch(params);
      console.log(resp);
      this.isLoading = false;
      for (let x in resp.data) {
        // console.log(unit);
        if (resp.data[x].is_vacant == true) {
          this.units.push(resp.data[x]);
        }
      }
      console.log(this.units);
    },
  },
  mounted() {
    this.getVacantUnits();
    this.actions = Object.keys(Constants.actions);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.auth {
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  position: absolute;
  right: 0px;
  top: 50px;
}
.home_button {
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  position: absolute;
  left: 0px;
  top: 50px;
}
.header {
  padding: 15px;
  text-align: center;
  background: #675fcf;
  color: rgb(255, 255, 255);
  font-size: 20px;
}
.date_picker {
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.actions {
  display: flex;
  background: #bfbce3;
}
.dash {
  display: inline-flex;
  max-width: 50rem;
  padding: 20px;
  text-align: center;
}
.float-child {
  width: 50%;
  padding: 20px;
  align-items: center;
  justify-content: center;
}
.vacancy {
  padding-bottom: 2.5rem;
}
footer {
  /* padding: 1px;
  text-align: center;
  background: #675fcf;
  color: rgb(255, 255, 255);
  
  position: relative;
  left: 0;
  right: 0;
  bottom: 0; */
  padding: 8px;
  height: 12rem;
  font-size: 15px;
  position: relative;
  bottom: 0;
  width: 100%;
  background: #675fcf;
  color: rgb(255, 255, 255);
}
</style>
