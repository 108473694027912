<template>
  <div class="signup">
    <div class="header">
      <h1>RENTO</h1>
      <div class="home_button">
        <button @click="gotoDash">DashBoard</button>
      </div>
      <div class="auth">
        <button @click="logout">Logout</button>
      </div>
    </div>

    <form @submit.prevent="addUnit">
      <label for="property">Property:</label>
      <select
        v-model="body.property_id"
        name="property"
        id="property"
        :disabled="isEdit"
      >
        <option
          v-for="(prop, index) in properties"
          :key="index"
          :value="prop.id"
        >
          {{ prop.property_name }}
        </option></select
      ><br />
      <label for="unit">Unit Type:</label>
      <select v-model="body.unit_type" name="unit" id="'unit">
        <option v-for="(unittype, index) in unittypes" :key="index">
          {{ unittype }}
        </option></select
      ><br />
      <label for="unitname">Unit Name:</label>
      <input
        v-model="body.unit_name"
        type="text"
        id="unitname"
        name="unitname"
      /><br />
      <button type="submit">Save</button>
    </form>
    <base-spinner v-if="isLoading"></base-spinner>
    <footer>
      <p><strong>About us</strong></p>
      <p>
        Rento is a completly free rental property management application
        designed for small to mid size.
      </p>
      <p>
        we are comitted to roll out new features based on customer experience.
      </p>
      <div class="address">
        <p><strong>Rento</strong></p>
        <p>192, Gandhi road, Tirupati, Andhra pradesh, India</p>
      </div>
    </footer>
  </div>
</template>

<script>
//:selected="body.property_id"
//:disabled="isEdit == true && prop.id != body.property_id"
import OwnerMethods from "../../api/resources/OwnerMethods.js";
import Constants from "../../constants/Constants.js";
const propertyparams = new URLSearchParams({
  entity: "property",
});
var unitParams = new URLSearchParams({
  entity: "unit",
});
export default {
  data() {
    return {
      isLoading:false,
      isEdit: false,
      properties: [],
      unittypes: [],
      body: {
        transmission_id: crypto.randomUUID(),
        is_vacant: true,
        unit_name: "",
        property_id: "",
        unit_type: "",
      },
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace("/");
    },
    gotoDash() {
      this.$router.push("/dash");
    },
    async addUnit() {
      this.isLoading = true;
      //console.log(this.body.property_id);
      if (this.isEdit == true) {
        unitParams.append("action", "edit");
        console.log(unitParams.toString());
      }
      const resp = await OwnerMethods.ownerStore(this.body, unitParams);
      console.log(resp);
      this.isLoading = false;
      if (resp.status == 200) {
        this.$store.state.isEdit = false;
        this.$router.push("/units");
      }
    },
    async getProps() {
      const resp = await OwnerMethods.ownerFetch(propertyparams);
      console.log(resp);
      this.properties = resp.data;
    },
  },
  mounted() {
    this.getProps();
    this.unittypes = Constants.unittypes;
    if (this.$store.state.isEdit == true) {
      this.body = this.$store.state.entity;
      this.isEdit = this.$store.state.isEdit;
    }
  },
  unmounted() {
    this.$store.state.isEdit = false;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.auth {
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  position: absolute;
  right: 0px;
  top: 50px;
}
.home_button {
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  position: absolute;
  left: 0px;
  top: 50px;
}
.header {
  padding: 15px;
  text-align: center;
  background: #675fcf;
  color: rgb(255, 255, 255);
  font-size: 20px;
}
.date_picker {
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
footer {
  padding: 1px;
  text-align: center;
  background: #675fcf;
  color: rgb(255, 255, 255);
  font-size: 15px;
  bottom: 0;
  position: relative;
  height: 180px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
}
</style>
