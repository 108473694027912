<template>
  <div class="signup">
    <div class="header">
      <h1>RENTO</h1>
      <div class="home_button">
          <button @click="gotoDash">DashBoard</button>
      </div>
      <div class="auth">
        <button @click="logout">Logout</button>
      </div>
    </div>

    <form @submit.prevent="addTenant">
      <div v-if="isEdit == true">
        <label for="is_active" class="is_active">Tenant:</label>
        <input
          v-model="body.is_active"
          id="is_active"
          class="switch"
          type="checkbox"
        /><br />
      </div>
      <label for="lname">Name:</label>
      <input v-model="body.name" type="text" id="lname" name="lname" /><br />
      <label for="contact">Phone number:</label>
      <input
        v-model="body.contact_number"
        type="number"
        id="contact"
        name="contact"
        required
      /><br />
      <label for="alternative_contact">Alternative Phone number:</label>
      <input
        v-model="body.alternative_contact_number"
        type="number"
        id="alternative_contact"
        name="alternative_contact"
      /><br />
      <label for="deposit">Deposit amount:</label>
      <input
        v-model="body.deposit_amount"
        type="number"
        id="deposit"
        name="deposit"
        required
      /><br />
      <label for="rent">Rent amount:</label>
      <input
        v-model="body.rent_amount"
        type="number"
        id="rent"
        name="rent"
        required
      /><br />
      <label for="property">Property:</label>
      <select
        v-model="body.property_id"
        name="property"
        id="'property"
        :disabled="isEdit"
      >
        <option
          v-for="(prop, index) in properties"
          :key="index"
          :value="prop.id"
        >
          {{ prop.property_name }}
        </option></select
      ><br />
      <label for="unit">Unit:</label>
      <select v-model="body.unit_id" name="unit" id="unit">
        <option v-for="(unit, index) in returnUnits" :key="index" :value="unit.id">
          {{ unit.unit_name }}
        </option></select
      ><br />
      <div class="date_picker">
        <label for="start_date_pick">Lease start date:</label>
        <VueDatePicker
          v-model="body.lease_start_date"
          id="start_date_pick"
          name="start_date_pick"
        />
        <label for="end_date_pick">Lease end date:</label>
        <VueDatePicker
          v-model="body.lease_end_date"
          id="end_date_pick"
          name="end_date_pick"
        />
      </div>
      <!-- <label for="due">Rent due date:</label>
      <select v-model="body." name="due" id="'due">
        <option v-for="num in nums" :key="num">{{ num }}</option></select
      ><br /> -->
      <label for="notes">Notes:</label>
      <textarea v-model="body.notes" rows="4" cols="30">add notes here</textarea
      ><br />
      <button type="submit">Save</button>
    </form>
    <base-spinner v-if="isLoading"></base-spinner>
    <footer>
      <p><strong>About us</strong></p>
      <p>
        Rento is a completly free rental property management application
        designed for small to mid size.
      </p>
      <p>
        we are comitted to roll out new features based on customer experience.
      </p>
      <div class="address">
        <p><strong>Rento</strong></p>
        <p>192, Gandhi road, Tirupati, Andhra pradesh, India</p>
      </div>
    </footer>
  </div>
</template>

<script>
// import { ref } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import OwnerMethods from "../../api/resources/OwnerMethods.js";
const tenantparams = new URLSearchParams({
  entity: "tenant",
});
const propertyparams = new URLSearchParams({
  entity: "property",
});
const unitparams = new URLSearchParams({
  entity: "unit",
});
export default {
  components: { VueDatePicker },
  data() {
    return {
      isLoading:false,
      isEdit: false,
      start_date: new Date(),
      end_date: new Date(),
      properties: [],
      units: [],
      body: {
        transmission_id: crypto.randomUUID(),
        name: "",
        contact_number: "",
        alternative_contact_number: "",
        property_id: "",
        unit_id: "",
        deposit_amount: "",
        rent_amount: "",
        lease_start_date: new Date(),
        lease_end_date: new Date(),
        notes: "",
        is_active: true,
      },
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace("/");
    },
    gotoDash() {
      this.$router.push("/dash");
    },
    async addTenant() {
      this.isLoading=true;
      if (this.isEdit == true) {
        tenantparams.append("action", "edit");
        console.log(tenantparams.toString());
      }
      if (typeof this.body.lease_end_date != "string") {
        this.body.lease_end_date = this.body.lease_end_date.toISOString();
      }
      if (typeof this.body.lease_start_date != "string") {
        this.body.lease_start_date = this.body.lease_start_date.toISOString();
      }
      console.log(this.body);
      const resp = await OwnerMethods.ownerStore(this.body, tenantparams);
      this.isLoading=false;
      console.log(resp);
      if (resp.status == 200) {
        this.$store.state.isEdit = false;
        this.$router.push("/tenants");
      }
    },
    async getProps() {
      const resp = await OwnerMethods.ownerFetch(propertyparams);
      console.log(resp);
      this.properties = resp.data;
    },
    async getUnits() {
      const resp = await OwnerMethods.ownerFetch(unitparams);
      console.log(resp);
      this.units = resp.data;
    },
  },
  computed: {
    returnUnits() {
      const units = [];
      for (let x in this.units) {
        if (this.units[x].property_id == this.body.property_id) {
          if (this.units[x].is_vacant == true) {
            units.push(this.units[x]);
          } else if (
            this.isEdit == true &&
            this.units[x].id == this.body.unit_id
          ) {
            units.push(this.units[x]);
          }
        }
      }
      return units;
    },
  },
  mounted() {
    this.getProps();
    this.getUnits();
    if (this.$store.state.isEdit == true) {
      this.body = this.$store.state.entity;
      this.isEdit = this.$store.state.isEdit;
    }
  },
  unmounted() {
    this.$store.state.isEdit = false;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.auth {
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  position: absolute;
  right: 0px;
  top: 50px;
}
.home_button {
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  position: absolute;
  left: 0px;
  top: 50px;
}
.header {
  padding: 15px;
  text-align: center;
  background: #675fcf;
  color: rgb(255, 255, 255);
  font-size: 20px;
}
.date_picker {
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
footer {
  padding: 1px;
  text-align: center;
  background: #675fcf;
  color: rgb(255, 255, 255);
  font-size: 15px;
  bottom: 0;
  position: relative;
  height: 180px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
}
.switch {
  position: relative;
  height: 1.5rem;
  width: 3rem;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 9999px;
  background-color: rgba(100, 116, 139, 0.377);
  transition: all 0.3s ease;
}

.switch:checked {
  background-color: rgba(236, 72, 153, 1);
}

.switch::before {
  position: absolute;
  content: "";
  left: calc(1.5rem - 1.6rem);
  top: calc(1.5rem - 1.6rem);
  display: block;
  height: 1.6rem;
  width: 1.6rem;
  cursor: pointer;
  border: 1px solid rgba(100, 116, 139, 0.527);
  border-radius: 9999px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 3px 10px rgba(100, 116, 139, 0.327);
  transition: all 0.3s ease;
}

.switch:hover::before {
  box-shadow: 0 0 0px 8px rgba(0, 0, 0, 0.15);
}

.switch:checked:hover::before {
  box-shadow: 0 0 0px 8px rgba(236, 72, 153, 0.15);
}

.switch:checked:before {
  transform: translateX(100%);
  border-color: rgba(236, 72, 153, 1);
}
</style>
