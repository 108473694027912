<template>
  <div class="signup">
    <div class="header">
      <h1>RENTO</h1>
      <div class="home_button">
          <button @click="gottoDash">DashBoard</button>
      </div>
      <div class="auth">
        <button @click="editTenant">Edit</button>
        <button @click="deleteTenant">Delete</button>
        <button @click="logout">Logout</button>
      </div>
    </div>
    <ul>
      <BaseCard v-for="attr in tenant" :key="attr">{{ attr }}</BaseCard>
      <br />
    </ul>

    <footer>
      <p><strong>About us</strong></p>
      <p>
        Rento is a completly free rental property management application
        designed for small to mid size.
      </p>
      <p>
        we are comitted to roll out new features based on customer experience.
      </p>
      <div class="address">
        <p><strong>Rento</strong></p>
        <p>192, Gandhi road, Tirupati, Andhra pradesh, India</p>
      </div>
    </footer>
  </div>
</template>

<script>
import BaseCard from "../cards/BaseCard.vue";
import OwnerMethods from "../../api/resources/OwnerMethods.js";
const tenantDeleteParams = new URLSearchParams({
  entity: "tenant",
  action: "delete",
});
export default {
  components: { BaseCard },
  data() {
    return {
      tenant: {},
      body: {
        id: null,
      },
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace("/");
    },
    gotoDash() {
      this.$router.push("/dash");
    },
    async editTenant() {
      this.$store.state.isEdit = true;
      this.$store.state.entity = this.tenant;
      this.$router.push("/addTenant");
    },
    async deleteTenant() {
      console.log(this.tenant);
      if (this.tenant.is_active == true) {
        return alert("Disable the tenant inorder to delete");
        //return null;
      }
      this.body.id = this.tenant.id;
      const resp = await OwnerMethods.ownerStore(this.body, tenantDeleteParams);
      console.log(resp);
      this.$router.push("/tenants");
    },
  },
  mounted() {
    this.tenant = this.$store.state.entity;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.auth {
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  position: absolute;
  right: 0px;
  top: 50px;
}
.home_button {
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  position: absolute;
  left: 0px;
  top: 50px;
}
.header {
  padding: 15px;
  text-align: center;
  background: #675fcf;
  color: rgb(255, 255, 255);
  font-size: 20px;
}
.date_picker {
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
footer {
  padding: 1px;
  text-align: center;
  background: #675fcf;
  color: rgb(255, 255, 255);
  font-size: 15px;
  bottom: 0;
  position: relative;
  height: 180px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
}
</style>
