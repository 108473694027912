//import { APISettings } from "../config.js";
import axios from "axios";

var APISettings = {
  headers: new Headers({
    Accept: "application/json",
  }),
  baseURL: "https://83kv8rdnv4.execute-api.us-east-1.amazonaws.com/dev",
};

export default {
  async auth(data, param) {
    APISettings.headers.set("Content-Type", "application/json");

    return await axios
      .post(
        APISettings.baseURL + "/owner/" + param + "/",
        data,
        APISettings.headers
      )
      .then((response) => {
        console.log(response);
        return response;
      });
  },
  async ownerFetch(params) {
    console.log("---------");
    console.log(localStorage.getItem("idToken"));
    return await axios
      .get(APISettings.baseURL + "/owner" + "?" + params.toString(), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("idToken"),
        },
      })
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async ownerStore(data, params) {
    // APISettings.headers.set("Content-Type", "application/json");
    // APISettings.headers["Authorization"] =
    //   "Bearer " + localStorage.getItem("idToken");
    return await axios
      .post(APISettings.baseURL + "/owner" + "?" + params.toString(), data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("idToken"),
        },
      })
      .then((response) => {
        console.log(response);
        return response;
      });
  },
};
