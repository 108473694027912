<template>
    <div>
      <slot></slot>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {};
    },
    methods: {},
  };
  </script>
  
  <style scoped>
  div{
      margin:2rem auto;
      max-width: 30rem;
      border-radius: 12px;
      box-shadow: 02px 8px rgb(60, 60, 64);
      padding:1rem;
  }
  </style>
  