<template>
  <div class="signup">
    <div class="header">
      <h1>RENTO</h1>
      <div class="home_button">
        <button @click="gotoDash">DashBoard</button>
      </div>
      <div class="auth">
        <button @click="gotoAddTenant">Add Tenant</button>
        <button @click="logout">Logout</button>
      </div>
    </div>
    <base-spinner v-if="isLoading"></base-spinner>
    <div v-if="!isLoading && tenants.length == 0">
      No Tenants are available, Add a Tenant!
    </div>
    <ul>
      <BasicCard
        v-for="(tenant, index) in tenants"
        :key="index"
        :entity="tenant"
        :type="'tenant'"
        >{{ tenant.name }}
        {{ tenant.is_active ? "Active" : "InActive" }}</BasicCard
      >
      <br />
    </ul>

    <footer>
      <p><strong>About us</strong></p>
      <p>
        Rento is a completly free rental property management application
        designed for small to mid size.
      </p>
      <p>
        we are comitted to roll out new features based on customer experience.
      </p>
      <div class="address">
        <p><strong>Rento</strong></p>
        <p>192, Gandhi road, Tirupati, Andhra pradesh, India</p>
      </div>
    </footer>
  </div>
</template>

<script>
//import { ref } from 'vue';
//`${num.unit_name} ${num.unit_type}`
import BasicCard from "../cards/BasicCard.vue";
import OwnerMethods from "../../api/resources/OwnerMethods.js";
const params = new URLSearchParams({
  entity: "tenant",
});

export default {
  components: { BasicCard },
  data() {
    return { tenants: [], isLoading: false };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace("/");
    },
    gotoDash() {
      this.$router.push("/dash");
    },
    gotoAddTenant() {
      this.$router.push("/addTenant");
    },
    async getTenants() {
      this.isLoading=true;
      const resp = await OwnerMethods.ownerFetch(params);
      this.isLoading=false;
      console.log(resp);
      this.tenants = resp.data;
      //return fe;
    },
  },
  mounted() {
    this.getTenants();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.auth {
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  position: absolute;
  right: 0px;
  top: 50px;
}
.home_button {
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  position: absolute;
  left: 0px;
  top: 50px;
}
.header {
  padding: 15px;
  text-align: center;
  background: #675fcf;
  color: rgb(255, 255, 255);
  font-size: 20px;
}
.date_picker {
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
footer {
  padding: 1px;
  text-align: center;
  background: #675fcf;
  color: rgb(255, 255, 255);
  font-size: 15px;
  bottom: 0;
  position: relative;
  height: 180px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
}
</style>
