<template>
  <div class="signup">
    <div class="header">
      <h1>RENTO</h1>
      <div class="auth">
        <button @click="gotoLogin">Login</button>
      </div>
      <div class="home_button">
        <button @click="gotoHome">Home</button>
      </div>
    </div>

    <form @submit.prevent="submitToCognito">
      <label for="fullname">First name:</label>
      <input
        v-model="body.first_name"
        type="text"
        id="fname"
        name="fname"
        required
      /><br />
      <label for="lname">Last name:</label>
      <input
        v-model="body.last_name"
        type="text"
        id="lname"
        name="lname"
      /><br />
      <label for="email">Email ID:</label>
      <input
        v-model="body.email"
        type="email"
        id="email"
        name="email"
        required
      /><br />
      <label for="password">Password:</label>
      <input
        v-model="body.password"
        type="password"
        id="password"
        name="password"
        required
      /><br />
      <div>
        <label for="contact">Phone number:</label>
        <input
          v-model="body.contact_number"
          type="number"
          id="contact"
          name="contact"
          required
        /><br />
        <label for="country">Country:</label>
        <country-select
          v-model="body.country"
          id="country"
          :country="body.country"
          topCountry="IN"
          required
        /><br />
        <label for="state">State:</label>
        <region-select
          v-model="body.state"
          id="region"
          :country="body.country"
          :region="body.state"
          required
        />
      </div>
      <button type="submit">Submit</button>
    </form>
    <form @submit.prevent="submitCodeToCognito">
      <div v-if="signupSuccess == true">
        <p>
          Confirmation code has been mailed to your email address, please enter
          it in the below field.
        </p>
        <label for="otp">Confirmation code:</label>
        <input v-model="otp" type="text" id="otp" name="otp" required /><br />
        <button type="submit">Submit Code</button>
      </div>
      <div v-else>
        <p>{{ signupResponse }}</p>
      </div>
      <div v-if="confimationSuccess == true">
        <p>User confirmation is successful</p>
      </div>
      <div v-else>
        <p>{{ confimationResponse }}</p>
      </div>
    </form>
    <base-spinner v-if="isLoading"></base-spinner>
    <footer>
      <h3>About us</h3>
      <p>
        Rento is a completly free rental property management application
        designed for small to mid size.
      </p>
      <p>
        we are comitted to roll out new features based on customer experience.
      </p>
      <div class="address">
        <h4>Rento</h4>
        <p>192, Gandhi road, Tirupati, Andhra pradesh, India</p>
      </div>
    </footer>
  </div>
</template>

<script>
import OwnerMethods from "../api/resources/OwnerMethods.js";
const signupParam = "signup";
const confirmCodeParam = "confirmsignup";

export default {
  components: {},
  data() {
    return {
      isLoading:false,
      body: {
        transmission_id: crypto.randomUUID(),
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        contact_number: "",
        country: "",
        state: "",
      },

      signupSuccess: false,
      confimationSuccess: false,
      otp: 0,
      signupResponse: "",
      confimationResponse: "",
    };
  },
  methods: {
    gotoHome() {
      this.$router.push("/");
    },
    gotoLogin() {
      this.$router.push("/login");
    },
    onSelect({ name, iso2, dialCode }) {
      console.log(name, iso2, dialCode);
    },
    async submitToCognito() {
      this.isLoading = true;
      const resp = await OwnerMethods.auth(this.body, signupParam);
      this.signupResponse = resp.data;
      this.isLoading=false;
      if (resp.status == 200) {
        this.signupSuccess = true;
      }
    },
    async submitCodeToCognito() {
      this.isLoading=true;
      const reqBody = {
        email: this.body.email,
        confirmationcode: this.otp,
      };
      const resp = await OwnerMethods.auth(reqBody, confirmCodeParam);
      this.confimationResponse = resp.data;
      this.isLoading=false;
      if (resp.status == 200) {
        this.confimationSuccess = true;
        this.$router.push("/login");
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.auth {
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  position: absolute;
  right: 0px;
  top: 60px;
}
.home_button {
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  position: absolute;
  left: 0px;
  top: 50px;
}
.header {
  padding: 15px;
  text-align: center;
  background: #675fcf;
  color: rgb(255, 255, 255);
  font-size: 20px;
}

footer {
  left:0;
  font-size: 15px;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #675fcf;
  color: rgb(255, 255, 255);
}
</style>
