<template>
  <div class="action">
    <button
      @click="goToCorrespondingPage"
      style="border-radius: none"
      class="fancy-btn"
    >
      <slot></slot>
    </button>
  </div>
</template>

<script>
import Constants from "../../constants/Constants.js";
const actions = Constants.actions;
export default {
  data() {
    return {};
  },
  props: {
    action: String,
  },
  methods: {
    goToCorrespondingPage() {
      var route = "";
      // console.log("-----------")
      // console.log(this.action);
      // console.log("-----------")
      // console.log(actions);
      // console.log("-----------")
      for (let key in actions) {
        console.log(key);
        if (key == this.action) {
          route = actions[key];
        }
      }
      this.$router.push("/" + route);
    },
  },
};
</script>

<style scoped>
.action {
  margin: 2rem auto;
  max-width: 5rem;
  background: #fdfdfc;
  justify-content: center;
  border-radius: 12px;
  box-shadow: 02px 8px rgb(60, 60, 64);
  padding: 1rem;
}
</style>
