<template>
  <div class="signup">
    <div class="header">
      <h1>RENTO</h1>
      <div class="auth">
        <button @click="gotoSignup">SignUp</button>
      </div>
      <div class="home_button">
        <button @click="gotoHome">Home</button>
      </div>
    </div>

    <form @submit.prevent="loginUser">
      <label for="email">Email ID:</label>
      <input
        v-model="body.email"
        type="email"
        id="email"
        name="email"
        required
      /><br />
      <div>
        <label for="pass">Password:</label>
        <input
          v-model="body.password"
          type="password"
          id="pass"
          name="pass"
          required
        /><br />
      </div>
      <button type="submit">Login</button>
    </form>
    <base-spinner v-if="isLoading"></base-spinner>
    <footer>
      <p><strong>About us</strong></p>
      <p>
        Rento is a completly free rental property management application
        designed for small to mid size.
      </p>
      <p>
        we are comitted to roll out new features based on customer experience.
      </p>
      <div class="address">
        <p><strong>Rento</strong></p>
        <p>192, Gandhi road, Tirupati, Andhra pradesh, India</p>
      </div>
    </footer>
  </div>
</template>

<script>
import OwnerMethods from "../api/resources/OwnerMethods.js";
const LoginParam = "login";
export default {
  data() {
    return {
      isLoading: false,
      body: {
        email: "",
        password: "",
        h: 0,
      },

      loginResponse: "",
    };
  },
  methods: {
    gotoHome() {
      this.$router.push("/");
    },
    gotoSignup() {
      this.$router.push("/signup");
    },
    async loginUser() {
      let error;
      console.log("hi");
      console.log(this.body);
      this.isLoading = true;
      const resp = await OwnerMethods.auth(this.body, LoginParam);
      this.loginResponse = resp.data;
      if (resp.status != 200) {
        error = new Error("Failed to authenticate. Check your login data.");
        console.log(error);
        // throw error;
      }
      await this.$store.dispatch("login", this.loginResponse);
      this.isLoading = false;
      this.$router.push("/dash");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.auth {
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  position: absolute;
  right: 0px;
  top: 50px;
}
.home_button {
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  position: absolute;
  left: 0px;
  top: 50px;
}
.header {
  padding: 15px;
  text-align: center;
  background: #675fcf;
  color: rgb(255, 255, 255);
  font-size: 20px;
}

footer {
  left: 0;
  font-size: 15px;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #675fcf;
  color: rgb(255, 255, 255);
}
</style>
