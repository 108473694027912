<template>
  <div class="home">
    <div class="header">
      <h1>RENTO</h1>
      <div class="auth">
        <button @click="gotoLogin">Login</button>
        <button @click="gotoSignUp">SignUp</button>
      </div>
    </div>
    <div class="content">
      <img
        src="../assets/homePage.jpg"
        alt="digital property management"
        width="500"
        height="300"
      />
      <p>property management software that saves your time and effort.</p>
      <h3>Built by landlord for landlords</h3>
      <ul>
        <li><strong>Tenant Onboarding</strong></li>
        <li><strong>Property management </strong></li>
        <li></li>
      </ul>
    </div>
    <footer>
      <h3>About us</h3>
      <p>
        Rento is a completly free rental property management application
        designed for small to mid size.
      </p>
      <p>
        we are comitted to roll out new features based on customer experience.
      </p>
      <div class="address">
        <h4>Rento</h4>
        <p>192, Gandhi road, Tirupati, Andhra pradesh, India</p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "HomePage",
  props: {
    msg: String,
  },
  methods: {
    gotoLogin() {
      this.$router.push("/login");
    },
    gotoSignUp() {
      this.$router.push("/signup");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.auth {
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  position: absolute;
  right: 0px;
  top: 60px;
}
a {
  color: #42b983;
}
.header {
  padding: 15px;
  text-align: center;
  background: #675fcf;
  color: rgb(255, 255, 255);
  font-size: 20px;
}

footer {
  font-size: 15px;
  position: relative;
  left:0;
  bottom: 0;
  width: 100%;
  background: #675fcf;
  color: rgb(255, 255, 255);
}
</style>
