import { createApp } from "vue";
import App from "./App.vue";
import vueCountryRegionSelect from "vue3-country-region-select";
import VueDatePicker from "@vuepic/vue-datepicker";
import { createRouter, createWebHistory } from "vue-router";
import Log_in from "./components/Log_in.vue";
import SignUp from "./components/SignUp.vue";
import HomePage from "./components/HomePage.vue";
import Unit from "./components/entity/UnitView.vue";
import Tenant from "./components/entity/TenantView.vue";
import Property from "./components/entity/PropertyView.vue";
import Reports from "./components/Reports.vue";
import ListUnit from "./components/listview/ListUnit.vue";
import ListProperties from "./components/listview/ListProperties.vue";
import ListTenants from "./components/listview/ListTenants.vue";
import Dash from "./components/Dash.vue";
import TenantAddition from "./components/addentities/TenantAddition";
import PropertyAddition from "./components/addentities/PropertyAddition";
import UnitAddition from "./components/addentities/UnitAddition";
import BaseSpinner from "./components/cards/BaseSpinner.vue";
import { createStore } from "vuex";

let timer;
const store = createStore({
  state() {
    return {
      entity: null,
      isEdit: false,
      expiresIn: null,
      userId: null,
      token: null,
      didAutoLogout: false,
    };
  },
  getters: {
    token(state) {
      return state.token;
    },
    isAuthenticated(state) {
      return state.token != null
        ? true
        : false || localStorage.getItem("idToken") != null
        ? true
        : false;
    },

    didAutoLogout(state) {
      return state.didAutoLogout;
    },
  },
  actions: {
    async login(context, respBody) {
      console.log(respBody.id_token);
      console.log("---expires in------");
      console.log(respBody);
      const expiresIn = +respBody.expires_in * 1000;
      // const expiresIn = 10000; //1690353015;
      const expirationDate = new Date().getTime() + expiresIn;
      console.log("---expires date format------");
      console.log(expirationDate.toString());
      localStorage.setItem("idToken", respBody.id_token);
      localStorage.setItem("tokenExpiration", expirationDate);
      timer = setTimeout(function () {
        context.dispatch("autoLogout");
      }, expiresIn);
      console.log("here once more--------------");
      console.log(respBody);
      context.commit("setUser", respBody);
    },
    logout(context) {
      localStorage.removeItem("idToken");
      localStorage.removeItem("tokenExpiration");

      clearTimeout(timer);

      context.commit("setUser", {
        token: null,
      });
    },
    autoLogout(context) {
      context.dispatch("logout");
      context.commit("setAutoLogout");
    },
  },
  mutations: {
    setUser(state, respBody) {
      console.log("Hey token is set ___________-----------");
      console.log(respBody);
      console.log(respBody.id_token);
      state.token = respBody.id_token;
      state.didAutoLogout = false;
      console.log(state.token);
    },
    setAutoLogout(state) {
      state.didAutoLogout = true;
    },
  },
});
const router = createRouter({
  routes: [
    { path: "/", redirect: "/home", meta: { requiresUnauth: true } }, //heirarchy in routes should be managed
    { path: "/home", component: HomePage, meta: { requiresUnauth: true } },
    {
      name: "login",
      path: "/login",
      component: Log_in,
      meta: { requiresUnauth: true },
      //alias: '/ ',
      // children: [
      //   {
      //     name: 'team-members',
      //     path: ':teamId',
      //     component: TeamMembers,
      //     props: true,
      //   },
      // ],
    },
    { path: "/signup", component: SignUp, meta: { requiresUnauth: true } },
    { path: "/dash", component: Dash, meta: { requiresAuth: true } },
    { path: "/units", component: ListUnit, meta: { requiresAuth: true } },
    { path: "/props", component: ListProperties, meta: { requiresAuth: true } },
    { path: "/tenants", component: ListTenants, meta: { requiresAuth: true } },
    {
      path: "/addTenant",
      component: TenantAddition,
      meta: { requiresAuth: true },
    },
    {
      path: "/addProperty",
      component: PropertyAddition,
      meta: { requiresAuth: true },
    },
    { path: "/addUnit", component: UnitAddition, meta: { requiresAuth: true } },
    { path: "/reports", component: Reports, meta: { requiresAuth: true } },
    { path: "/unit", component: Unit, meta: { requiresAuth: true } },
    { path: "/tenant", component: Tenant, meta: { requiresAuth: true } },
    { path: "/property", component: Property, meta: { requiresAuth: true } },
  ],
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    console.log(to + from + savedPosition);
    if (savedPosition) {
      return savedPosition;
    }
    return {
      left: 0,
      top: 0,
    };
  },
});

router.beforeEach(function (to, _, next) {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    next("/login");
    // } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
    //   next("/");
  } else {
    next();
  }
});

const app = createApp(App);
app.use(router);
app.use(store);
app.use(vueCountryRegionSelect);
app.component("base-spinner", BaseSpinner);
app.component("VueDatePicker", VueDatePicker);
app.mount("#app");
