<template>
  <div class="signup">
    <div class="header">
      <h1>RENTO</h1>
      <div class="home_button">
        <button @click="gotoDash">DashBoard</button>
      </div>
      <div class="auth">
        <button @click="logout">Logout</button>
      </div>
    </div>

    <form @submit.prevent="addProperty">
      <label for="pname">Property Name:</label>
      <input
        v-model="body.property_name"
        type="text"
        id="pname"
        name="pname"
      /><br />
      <div>
        <label for="ptype">Property Type:</label>
        <select
          v-model="body.property_type"
          name="ptype"
          id="ptype"
          :disabled="isEdit"
        >
          <option v-for="(ptype, index) in ptypes" :key="index">
            {{ ptype }}
          </option></select
        ><br />
      </div>
      <label for="city">City Name:</label>
      <input v-model="body.city" type="text" id="city" name="city" /><br />
      <label for="address">Address:</label>
      <textarea v-model="body.address" rows="4" cols="30">
Add notes here</textarea
      ><br />
      <button type="submit">Save</button>
    </form>
    <base-spinner v-if="isLoading"></base-spinner>
    <footer>
      <p><strong>About us</strong></p>
      <p>
        Rento is a completly free rental property management application
        designed for small to mid size.
      </p>
      <p>
        we are comitted to roll out new features based on customer experience.
      </p>
      <div class="address">
        <p><strong>Rento</strong></p>
        <p>192, Gandhi road, Tirupati, Andhra pradesh, India</p>
      </div>
    </footer>
  </div>
</template>

<script>
import OwnerMethods from "../../api/resources/OwnerMethods.js";
import Constants from "../../constants/Constants.js";
const propertyparams = new URLSearchParams({
  entity: "property",
});
export default {
  data() {
    return {
      isLoading:false,
      isEdit: false,
      ptypes: Constants.propertytypes,
      body: {
        transmission_id: crypto.randomUUID(),
        property_name: "",
        property_type: "",
        city: "",
        address: "",
      },
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace("/");
    },
    gotoDash() {
      this.$router.push("/dash");
    },
    async addProperty() {
      this.isLoading = true;
      if (this.isEdit == true) {
        propertyparams.append("action", "edit");
        console.log(propertyparams.toString());
      }
      //console.log(this.body.property_id);
      const resp = await OwnerMethods.ownerStore(this.body, propertyparams);
      console.log(resp);
      this.isLoading = false;
      if (resp.status == 200) {
        this.$store.state.isEdit = false;
        this.$router.push("/props");
      }
    },
  },
  mounted() {
    console.log("--out");
    if (this.$store.state.isEdit == true) {
      console.log("------true");
      this.body = this.$store.state.entity;
      this.isEdit = this.$store.state.isEdit;
    }
  },
  unmounted() {
    this.$store.state.isEdit = false;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.auth {
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  position: absolute;
  right: 0px;
  top: 50px;
}
.home_button {
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  position: absolute;
  left: 0px;
  top: 50px;
}
.header {
  padding: 15px;
  text-align: center;
  background: #675fcf;
  color: rgb(255, 255, 255);
  font-size: 20px;
}
.date_picker {
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
footer {
  padding: 1px;
  text-align: center;
  background: #675fcf;
  color: rgb(255, 255, 255);
  font-size: 15px;
  bottom: 0;
  position: relative;
  height: 180px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
}
</style>
